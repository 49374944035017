import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Image from "gatsby-image"
import slugify from "slugify"
import useBooks from "../hooks/useBooks"
import SEO from "../components/seo"
import { Link } from "gatsby"

export const pageQuery = graphql`
  query($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      frontmatter {
        title
        bio
        llibres
        imatge {
          sharp: childImageSharp {
            fluid(maxWidth: 768, maxHeight: 768, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
        }
      }
    }
  }
`

const AutorTemplate = ({ data: { markdownRemark: autor } }) => {
  const books = useBooks()

  return (
    <Layout>
      <SEO
        title={`${autor.frontmatter.title} | Autors`}
        description={autor.frontmatter.bio.substring(0, 200)}
        lang="ca"
        keywords={[
          "escriptor",
          "llibres",
          "literatura catalana",
          "terror",
          "ciència ficció",
          "neogrotesc",
          "clàssics",
          "surrealisme",
          "editors independents",
          "males herbes",
          "editorial",
          "narrativa",
          "català",
          "catalana",
          "autor",
          "autora",
          "escriptora",
        ]}
        image={`https://www.editorialmalesherbes.com/${autor.frontmatter.imatge.sharp.fluid.src}`}
      />
      <div className="flex flex-wrap">
        <div className="w-full ">
          <p className="font-title font-bold uppercase text-gray-900 text-xl border-b border-black  py-2">
            Autors
          </p>
        </div>
      </div>
      <div className="w-full mb-8">
        <p className="font-title antialiased text-gray-900 text-3xl mt-8">
          {autor.frontmatter.title}
        </p>
      </div>
      <div className="flex flex-wrap -mx-2 md:-mx-4 mt-12">
        <div class="w-full px-3  md:w-1/2">
          <Image fluid={autor.frontmatter.imatge.sharp.fluid} />
        </div>
        <div class="w-full md:w-1/2 px-4 text-lg mt-8 md:mt-0">
          <div
            dangerouslySetInnerHTML={{
              __html: autor.frontmatter.bio.replace(/\n/g, "<br/>"),
            }}
          />
        </div>
      </div>
      <div className="flex flex-wrap mt-8 mx-2 md:mx-0">
        <div className="w-full">
          <p className="font-title font-bold text-myblack text-md border-b border-black  py-4">
            Llibres
          </p>
        </div>
      </div>
      <div class="flex flex-wrap -mx-2 md:-mx-4">
        <div class="w-full md:w-1/2 px-4 text-lg mt-8 md:mt-0">
          {autor?.frontmatter?.llibres?.map(ll => {
            return (
              <Link
                to={`/cataleg/${slugify(ll, { lower: true })}`}
                class="hover:text-malesherbes"
              >
                <p className="font-medium mt-4 italic">- {ll}</p>
              </Link>
            )
          })}
        </div>
      </div>
    </Layout>
  )
}

export default AutorTemplate
